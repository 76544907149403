<template>
	<v-card flat>
		<v-card-title>{{ $t('ShoppingCart.Select_an_invoice_address') }}</v-card-title>
		<v-card-subtitle>{{ $t('ShoppingCart.If_no_invoice_adress') }}</v-card-subtitle>
		<v-card-text>
			<v-row>
				<v-col cols="3" v-for="address of invoiceAddressList" :key="address.addressId">
					<template>
						<address-card :address="address" />
					</template>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AddressCard from "./AddressCard.vue";
export default {
	name: "OrderInvoiceForm",
	components: { AddressCard },
	computed: {
		...mapGetters({
			invoiceAddressList: "shoppingCart/invoiceAddressList",
		}),
	},
};
</script>

