<template>
	<v-card flat outlined class="mx-auto mt-lg-16" max-width="1080">
		<v-card-title>{{ $t('ShoppingCart.Bestellen') }}</v-card-title>
		<v-stepper v-model="stepNr" class="elevation-0 pa-0">
			<v-stepper-header class="elevation-0">
				<v-stepper-step :complete="stepNr > 1" step="1">
					{{ $t('ShoppingCart.Producten') }}
				</v-stepper-step>
				<v-divider />
				<v-stepper-step :complete="stepNr > 2" step="2">
					{{ $t('ShoppingCart.Verzending') }}
				</v-stepper-step>
				<v-divider />
				<v-stepper-step :complete="stepNr > 3" step="3">
					{{ $t('ShoppingCart.Factuur') }}
				</v-stepper-step>
				<v-divider />
				<v-stepper-step :complete="stepNr > 4" step="4">
					{{ $t('ShoppingCart.Bestellen') }}
				</v-stepper-step>
				<v-divider />
				<v-stepper-step step="5"> {{ $t('ShoppingCart.Klaar') }} </v-stepper-step>
			</v-stepper-header>
			<v-stepper-items style="min-height: 300px;max-height: 60vh;overflow-y: auto;">
				<!-- STEP 1 -->
				<v-stepper-content step="1" class="py-0">
					<CartItemTable />
				</v-stepper-content>
				<!-- STEP 2 -->
				<v-stepper-content step="2" class="py-0">
					<OrderDeliveryForm />
				</v-stepper-content>
				<!-- STEP 3 -->
				<v-stepper-content step="3" class="py-0">
					<OrderInvoiceForm />
				</v-stepper-content>
				<!-- STEP 4 -->
				<v-stepper-content step="4" class="py-0">
					<v-card flat :max-width="600" class="mx-auto">
						<v-card-title>{{ $t('ShoppingCart.Bestelling_plaatsen') }}</v-card-title>
						<v-card-subtitle>{{ $t('ShoppingCart.Referentie_en_opmerking_meegeven')
							}}</v-card-subtitle>
						<v-card-text>
							<v-row dense>
								<v-col cols="12">
									<v-text-field v-model="reference" :label="$t('ShoppingCart.Referentie')"
										outlined></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-textarea v-model="remark" :label="$t('ShoppingCart.Opmerking')" outlined />
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-actions>
							<v-btn block color="primary" @click.stop="convertCart" :loading="loading">{{
								$t('ShoppingCart.Bestelling_plaatsen') }}</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
				<!-- STEP 5 -->
				<v-stepper-content step="5" class="py-0">
					<v-card flat max-width="600" class="mx-auto ">
						<v-card-title>
							{{ $t('ShoppingCart.Thank_you') }} {{ firstName }} !
						</v-card-title>
						<v-card-subtitle>
							{{ $t('ShoppingCart.We_will_send_an_order_confirmation') }}
						</v-card-subtitle>
						<v-card-text class="pt-8">
							<v-banner :icon="errorMsg ? 'mdi-close' : 'mdi-check'"
								:icon-color="errorMsg ? 'red' : 'green'">
								<span v-if="errorMsg">{{ this.errorMsg }}</span>
								<span v-else>{{ this.result }}</span>
								<template v-slot:actions>
									<v-btn plain :to="{ name: 'Home' }">
										{{ $t('ShoppingCart.OK') }}
									</v-btn>
								</template>
							</v-banner>
						</v-card-text>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		<v-card-actions>
			<v-btn v-show="showPrev" plain @click="prevStep">
				<v-icon>mdi-chevron-left</v-icon>{{ $t('ShoppingCart.Terug') }}
			</v-btn>
			<v-spacer />
			<v-btn v-show="showNext" text color="primary" @click="nextStep">
				<span class="px-2">{{ $t('ShoppingCart.Volgende') }}</span>
				<v-icon>mdi-chevron-right</v-icon>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { mapGetters } from "vuex";
import CartItemTable from "@/components/CartItemTable";
import OrderDeliveryForm from "@/components/OrderDeliveryForm";
import OrderInvoiceForm from "@/components/OrderInvoiceForm";

export default {
	name: "ShoppingCart",
	components: {
		CartItemTable,
		OrderDeliveryForm,
		OrderInvoiceForm,
	},
	data() {
		return {
			stepNr: 1,
			loading: false,
			errorMsg: null,
			result: "",
		};
	},
	activated() {
		this.stepNr = 1;
	},
	created() {
		this.$store.dispatch("shoppingCart/fetchShoppingCart").then(() => {
			this.$store.dispatch("shoppingCart/fetchAddressList");
		});
	},
	computed: {
		...mapGetters({
			cart: "shoppingCart/cart",
			cartItemCount: "shoppingCart/cartItemCount",
			deliveryAddressList: "shoppingCart/deliveryAddressList",
			invoiceAddressList: "shoppingCart/invoiceAddressList",
			firstName: "session/firstName",
			email: "session/"
		}),
		deliveryAddressCnt() {
			if (this.deliveryAddressList)
				return this.deliveryAddressList.length;
			else return 0;
		},
		invoiceAddressCnt() {
			if (this.invoiceAddressList) return this.invoiceAddressList.length;
			else return 0;
		},
		remark: {
			get() {
				return this.$store.state.shoppingCart.remark;
			},
			set(text) {
				this.$store.dispatch("shoppingCart/setRemark", text);
			},
		},
		reference: {
			get() {
				return this.$store.state.shoppingCart.reference;
			},
			set(text) {
				this.$store.dispatch("shoppingCart/setReference", text);
			},
		},
		showPrev() {
			switch (this.stepNr) {
				case 1:
					return false;
				case 2:
					return true;
				case 3:
					return true;
				case 4:
					return true;
				case 5:
					return false;
				default:
					return false;
			}
		},
		showNext() {
			switch (this.stepNr) {
				case 1:
					return this.cartItemCount > 0;
				case 2:
					return true;
				case 3:
					return true;
				default:
					return false;
			}
		},
	},
	methods: {
		prevStep() {
			switch (this.stepNr) {
				default:
					this.stepNr -= 1;
			}
		},
		nextStep() {
			switch (this.stepNr) {
				case 1:
					if (this.deliveryAddressCnt > 0) {
						this.stepNr = 2;
					} else {
						if (this.invoiceAddressCnt > 0) {
							this.stepNr = 3;
						} else {
							this.stepNr = 4;
						}
					}
					break;
				case 2:
					if (this.invoiceAddressCnt > 0) {
						this.stepNr = 3;
					} else {
						this.stepNr = 4;
					}
					break;
				default:
					this.stepNr += 1;
					break;
			}
		},
		convertCart() {
			let that = this;
			this.loading = true;
			this.$store
				.dispatch("shoppingCart/convertShoppingCart")
				.then((response) => {
					this.result =
						this.$t('ShoppingCart.Your_order_nr') + ': ' +
						response.data.success.orderId;
					this.$store.dispatch("session/fetchDashboardInfo");
				})
				.catch((error) => {
					this.errorMsg = error;
				})
				.finally(() => {
					window.setTimeout(function () {
						that.nextStep();
						that.loading = false;
					}, 2000);
				});
		},
	},
};
</script>
