<template>
	<v-card flat>
		<v-card-title>{{ $t('ShoppingCart.Je_gaat_deze_producten_bestellen') }}</v-card-title>
		<v-card-text>
			<v-sheet outlined>
				<v-data-table :items="cartItems" :headers="tableHeaders" disable-pagination disable-sort hide-default-footer
					no-data-text fixed-header height="200" dense>
					<template v-slot:[`item.actions`]="{ item }">
						<v-icon small @click="deleteCartItem(item)">mdi-delete</v-icon>
					</template>
					<template v-slot:[`item.quantity`]="{ item }">
						{{ item.quantity }}
					</template>
					<template v-slot:[`item.unitPriceExclVat`]="{ item }">
						{{ $n(item.unitPriceExclVat, "decimal") }}
					</template>
					<template v-slot:[`item.discountPerc`]="{ item }">
						<span v-if="item.discountPerc">
														{{ $n(item.discountPerc * -0.01, "percent") }}</span>
					</template>
					<template v-slot:[`item.lineNetAmount`]="{ item }">
						{{ $n(item.lineNetAmount, "decimal") }}
					</template>
				</v-data-table>
				<v-divider />
				<v-card-text class="text-right">
					<v-row dense>
						<v-col cols="6" offset-md="3"> {{ $t('ShoppingCart.Netto') }} </v-col>
						<v-col cols="6" md="3">
							{{ $n(cart.netAmount, "decimal") }}
						</v-col>
						<v-col cols="6" offset-md="3"> {{ $t('ShoppingCart.BTW') }} </v-col>
						<v-col cols="6" md="3">
							{{ $n(cart.vatAmount, "decimal") }}
						</v-col>
					</v-row>
					<v-row dense class="font-weight-medium ">
						<v-col cols="6" offset-md="3"> {{ $t('ShoppingCart.Totaal') }} </v-col>
						<v-col cols="6" md="3">
							<span class="mr-3">{{ cart.decimal }}</span>
							{{ $n(cart.totalAmount, "decimal") }}
						</v-col>
					</v-row>
				</v-card-text>
			</v-sheet>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "ShoppingCart",
	data() {
		return {
			loading: false,
			tableHeaders: [
				{
					text: "",
					value: "actions",
					align: "center",
				},
				{
					text: this.$t('ShoppingCart.Code'),
					value: "productCode",
				},
				{
					text: this.$t('ShoppingCart.Omschrijving'),
					value: "productName",
					width: "40%",
				},
				{
					text: this.$t('ShoppingCart.Aantal'),
					value: "quantity",
					align: "end",
				},
				{
					text: this.$t('ShoppingCart.EHP'),
					value: "unitPriceExclVat",
					align: "end",
				},
				{
					text: "",
					value: "discountPerc",
					align: "end",
				},
				{
					text: this.$t('ShoppingCart.Lijntotaal'),
					value: "lineNetAmount",
					align: "end",
				},
				{
					text: this.$t('ShoppingCart.BTW_perc'),
					value: "vatPerc",
					align: "end",
				},
			],
		};
	},
	computed: {
		...mapGetters({
			cart: "shoppingCart/cart",
			cartItems: "shoppingCart/cartItems",
		}),
	},
	methods: {
		deleteCartItem(pCartItem) {
			this.$store.dispatch("shoppingCart/deleteCartItem", pCartItem);
		},
	},
};
</script>



