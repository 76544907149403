<template>
	<v-card flat outlined @click.stop="selectAddress" :elevation="isSelected ? '1' : '0'">
		<v-card-title :class="isSelected ? 'primary white--text' : ''">
			<span class="text-subtitle-1">{{ address.organisationName }}</span>
			<v-spacer />
			<v-icon :color="isSelected ? 'white' : ''">mdi-checkbox-marked-outline</v-icon>
		</v-card-title>
		<v-card-subtitle :class="isSelected ? 'primary white--text' : ''">
			{{ address.attentionTo }}
		</v-card-subtitle>
		<v-divider />
		<v-card-text class="d-flex flex-column">
			<span>{{ address.streetAddress1 }}</span>
			<span v-if="address.streetAddress2">{{ address.streetAddress2 }}</span>
			<span>{{ address.countryCode }} &nbsp;{{ address.zipCode }} &nbsp;{{ address.city }}</span>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: "AddressCard",
	props: {
		address: Object,
	},
	data() {
		return {
			//isSelected: false,
		};
	},
	computed: {
		...mapGetters({
			deliveryAddressId: "shoppingCart/deliveryAddressId",
			invoiceAddressId: "shoppingCart/invoiceAddressId",
		}),
		isSelected() {
			if (this.address.addressType == "delivery") {
				return this.address.addressId == this.deliveryAddressId;
			}
			if (this.address.addressType == "invoice") {
				return this.address.addressId == this.invoiceAddressId;
			}
			return false;
		},
	},
	methods: {
		selectAddress() {
			if (this.address.addressType === "delivery") {
				this.$store.commit(
					"shoppingCart/setDeliveryAddressId",
					this.address.addressId
				);
			}
			if (this.address.addressType === "invoice") {
				this.$store.commit(
					"shoppingCart/setInvoiceAddressId",
					this.address.addressId
				);
			}
		},
	},
};
</script>
